import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  notVerifiedPartner: JSON.parse(localStorage.getItem("norVerifiedPartner"))
    ? JSON.parse(localStorage.getItem("norVerifiedPartner"))
    : [],
  verifiedPartner: JSON.parse(localStorage.getItem("verifiedPartner"))
    ? JSON.parse(localStorage.getItem("verifiedPartner"))
    : [],
  loading: false,
};
export const verifiedVehicleList = createAsyncThunk(
  "partner/verifiedVehicleList",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/partner/verifiedPartners`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const allNotVerifiedVehicleList = createAsyncThunk(
  "partner/allNotVerifiedVehicleList",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/partner/notVerifiedPartners`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const updatePartner = createAsyncThunk(
  "partner/updatePartner",
  async (formData,thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/partner/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifiedVehicleList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifiedVehicleList.fulfilled, (state, action) => {
        state.verifiedPartner = action.payload.partners;
        localStorage.setItem(
          "verifiedPartner",
          JSON.stringify(state.verifiedPartner)
        );
      })
      .addCase(verifiedVehicleList.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(allNotVerifiedVehicleList.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(allNotVerifiedVehicleList.fulfilled, (state, action) => {
        state.notVerifiedPartner = action.payload.partners;
        localStorage.setItem(
          "norVerifiedPartner",
          JSON.stringify(state.notVerifiedPartner)
        );
      })
      .addCase(allNotVerifiedVehicleList.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(updatePartner.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updatePartner.fulfilled, (state, action) => {
        state.notVerifiedPartner = state.notVerifiedPartner.filter(
          (indPartner) => indPartner._id !== action.payload.partner._id
        );
        state.verifiedPartner = [
          action.payload.partner,
          ...state.verifiedPartner,
        ];
        localStorage.setItem(
          "norVerifiedPartner",
          JSON.stringify(state.notVerifiedPartner)
        );
        localStorage.setItem(
          "verifiedPartner",
          JSON.stringify(state.verifiedPartner)
        );
        state.loading = false;
      })
      .addCase(updatePartner.rejected, (state, action) => {
        state.loading = true;
      });
  },
});
export default partnerSlice.reducer;
