import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login.jsx";
import { useDispatch, useSelector } from "react-redux";
import VehicleCreate from "./pages/Vehicle-magangement/VehicleCreate.jsx";
import GoodsType from "./pages/Goods-Management/GoodsType.jsx";
import GoodsTypeList from "./pages/Goods-Management/GoodsTypeList.jsx";
import { goodsTypeList } from "./redux/GoodsType/GoodsSlice.js";
import UpdateGoodsType from "./pages/Goods-Management/UpdateGoodsType.jsx";
import { allVehicleList } from "./redux/Vehicle/VehicleSlice.js";
import VehicleList from "./pages/Vehicle-magangement/VehicleList.jsx";
import { allNotVerifiedVehicleList, verifiedVehicleList } from "./redux/Partner/PartnerSlice.js";
import NotVerifiedPartner from "./pages/Partner-Management/NotVerifiedPartner.jsx";
import VerifiedPartner from "./pages/Partner-Management/VerifiedPartner.jsx";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { goodsTypeTotal } = useSelector((store) => store.goodsType)


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(goodsTypeList());
        dispatch(allVehicleList())
        dispatch(allNotVerifiedVehicleList())
        dispatch(verifiedVehicleList())
      } catch (error) {}
    };
    initialSetup();
  }, []);
  useEffect(() => {
    if (!goodsTypeTotal) {
      dispatch(goodsTypeList());
    }
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/admin/addvehicle" element={<VehicleCreate />} />
            <Route path="/admin/vehiclelist" element={<VehicleList />} />
            <Route path="/admin/goodstype" element={<GoodsType />} />
            <Route path="/admin/goodstypelist" element={<GoodsTypeList />} />
            <Route path="/admin/notverifiedpartnerlist" element={<NotVerifiedPartner />} />
            <Route path="/admin/verifiedpartnerlist" element={<VerifiedPartner />} />
            <Route
              path="/admin/updategoodstype/:_id"
              element={<UpdateGoodsType />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
