import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  vehicleTotal: [],
  notVerifiedVehicleTotal: [],
  isVehicleLoading: true,
  vehicleImage: "",
  isVehicleImageLoading: true,
  vehicleDetailImage: "",
  isVehicleDetailImageLoading: true,
};
export const handleVehicleCreate = createAsyncThunk(
  "vehicle/vehicleCreate",
  async (data, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/vehicle/new`;
      const resp = await axios.post(url, data, config);
      console.log(resp.data, "data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const allVehicleList = createAsyncThunk(
  "vehicle/allVehicle",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/vehicle/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const handleVehicleImage = createAsyncThunk(
  "vehicle/vehicleImage",
  async (data, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/vehicle/uploadvehicleimage`;
      const resp = await axios.post(url, data, config);
      console.log(resp.data, "data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const handleVehicleDetailImage = createAsyncThunk(
  "vehicle/vehicleDetailImage",
  async (data, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/vehicle/uploaddetailvehicleimage`;
      const resp = await axios.post(url, data, config);
      console.log(resp.data, "data");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
const vehicleSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    resetVehicleImage: (state, action) => {
      state.vehicleDetailImage = "";
      state.vehicleImage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleVehicleCreate.pending, (state, action) => {
        state.isVehicleLoading = true;
      })
      .addCase(handleVehicleCreate.fulfilled, (state, action) => {
        state.vehicleTotal = [...state.vehicleTotal, action.payload.vehicle];
        state.isVehicleLoading = false;
      })
      .addCase(handleVehicleCreate.rejected, (state, action) => {
        state.isVehicleLoading = true;
      })
      .addCase(allVehicleList.pending, (state, action) => {
        state.isVehicleLoading = true;
      })
      .addCase(allVehicleList.fulfilled, (state, action) => {
        state.vehicleTotal = action.payload.vehicles;
        state.isVehicleLoading = false;
      })
      .addCase(allVehicleList.rejected, (state, action) => {
        state.isVehicleLoading = true;
      })
      .addCase(handleVehicleImage.pending, (state, action) => {
        state.isVehicleImageLoading = true;
      })
      .addCase(handleVehicleImage.fulfilled, (state, action) => {
        state.vehicleImage = action.payload.vehicleImage;
        state.isVehicleImageLoading = false;
      })
      .addCase(handleVehicleImage.rejected, (state, action) => {
        state.isVehicleImageLoading = true;
      })
      .addCase(handleVehicleDetailImage.pending, (state, action) => {
        state.isVehicleDetailImageLoading = true;
      })
      .addCase(handleVehicleDetailImage.fulfilled, (state, action) => {
        state.vehicleDetailImage = action.payload.vehicleImage;
        state.isVehicleDetailImageLoading = false;
      })
      .addCase(handleVehicleDetailImage.rejected, (state, action) => {
        state.isVehicleDetailImageLoading = true;
      });
  },
});
export const { resetVehicleImage } = vehicleSlice.actions;
export default vehicleSlice.reducer;
