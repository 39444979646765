import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleVehicleCreate, handleVehicleDetailImage, handleVehicleImage, resetVehicleImage } from "../../redux/Vehicle/VehicleSlice";

const VehicleCreate = () => {
    const dispatch = useDispatch();
    const { vehicleImage, isVehicleImageLoading, vehicleDetailImage, isVehicleDetailImageLoading } = useSelector((store) => store.vehicle)
    const [name, setName] = useState('');
    const [maxWeight, setMaxWeight] = useState('');
    const [price, setPrice] = useState('');
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [length, setLength] = useState('');

    const imageChange = (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(handleVehicleImage({ desktopImage: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const detailImageChange = (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(handleVehicleDetailImage({ desktopImage: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = async () => {
        const formData = {
            name: name,
            maxWeight: maxWeight,
            vehicleImage: vehicleImage,
            vehicleDetailImage: vehicleDetailImage,
            pricePerKm: price,
            height: height,
            width: width,
            length: length
        }
        const vehicleData = await dispatch(handleVehicleCreate(formData))
        console.log(vehicleData.payload.success,"asdfgh");
        if (vehicleData.payload.success) {
            alert('Vehicle Created')
            dispatch(resetVehicleImage())
            setName('');
            setMaxWeight('');
            setPrice('');
            setHeight('');
            setWidth('');
            setLength('');
            setPrice('');
        }
    }

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Add Vehicle Details
                        </h4>

                    </div>
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-4 px-5">
                            <h4 className="text-lg font-medium text-default-950 capitalize">
                                Add Vehicle Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Name *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Max-Weight *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fWeight"
                                        placeholder="Enter Max Weight"
                                        value={maxWeight}
                                        onChange={(e) => setMaxWeight(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fVehicleImage"
                                        className="text-sm font-medium text-default-900"
                                    >Vehicle Image</label>
                                    <input
                                        type="file"
                                        placeholder="No File Choosen"
                                        onChange={imageChange}
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        multiple
                                        id="fVehicleImage"
                                        required
                                    />
                                    <div>
                                        {
                                            isVehicleImageLoading ? (<></>) : (<>
                                                <img src={vehicleImage} alt="vehicleImage" style={{ height: '100px', width: '100px' }} />
                                            </>)
                                        }
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDetailImage"
                                        className="text-sm font-medium text-default-900"
                                    >Vehicle Detail Image</label>
                                    <input
                                        type="file"
                                        id="fDetailImage"
                                        placeholder="No File Choosen"
                                        onChange={detailImageChange}
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        multiple
                                        required
                                    />
                                    <div>
                                        {
                                            isVehicleDetailImageLoading ? (<></>) : (<>
                                                <img src={vehicleDetailImage} alt="vehicleImage" style={{ height: '100px', width: '100px' }} />
                                            </>)
                                        }
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Price Per Km *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fPrice"
                                        placeholder="Enter Price"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fHeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Height *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fHeight"
                                        placeholder="Enter Height"
                                        value={height}
                                        onChange={(e) => setHeight(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fWidth"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Width *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fWidth"
                                        placeholder="Enter Width"
                                        value={width}
                                        onChange={(e) => setWidth(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLength"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Length *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fLength"
                                        placeholder="Enter Length"
                                        value={length}
                                        onChange={(e) => setLength(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '60px' }}>
                        <button
                            style={{ background: "#ef4444", color: 'text' }}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                        >
                            <i className="ph-bold ph-x text-lg" /> Reset
                        </button>
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{ background: "#8481ff" }}
                            onClick={() => handleSubmit()}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleCreate;
