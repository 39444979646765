import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  goodsTypeTotal: [],
  isGoodsLoading: true,
};

export const goodsTypeCreate = createAsyncThunk(
  "goods/goodsTypeCreate",
  async (formData, thunkApi) => {
    try {
      const config = {
        Headers: { "content-type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/goodstypes/new`;
      const response = await axios.post(url, formData, config);
      console.log(response.data, "asdfg");
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const goodsTypeUpdate = createAsyncThunk(
  "goods/goodsTypeUpdate",
  async (formData,id, thunkApi) => {
    try {
      const config = {
        Headers: { "content-type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/goodstypes/updategoodstype/${formData._id}`;
      console.log(url, "asdfg");
      const response = await axios.put(url, formData, config);
      console.log(response.data, "asdfg");
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const goodsTypeList = createAsyncThunk(
  "goods/goodsTypeList",
  async (thunkApi) => {
    try {
      const url = `${Baseurl}/api/v1/goodstypes/all`;
      const response = await axios.get(url);
      console.log(response.data.goodsTypeList,"asdfg");
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const verifySlugUrl = createAsyncThunk(
  "goods/Goodsname",
  async (data, thunkApi) => {
    try {
      const config = {
        Headers: { "content-type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/goodstypes/verifyslugurl/${data}`;
      const response = await axios.get(url, config);
      console.log(response.data, "asdfg");
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const goodsTypeSlice = createSlice({
  name: "goodsType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(goodsTypeCreate.pending, (state, action) => {
        state.isGoodsLoading = true;
      })
      .addCase(goodsTypeCreate.fulfilled, (state, action) => {
        state.goodsTypeTotal = [
          ...state.goodsTypeTotal,
          action.payload.goodType,
        ];
        state.isGoodsLoading = false;
      })
      .addCase(goodsTypeCreate.rejected, (state, action) => {
        state.isGoodsLoading = true;
      })
      .addCase(goodsTypeUpdate.pending, (state, action) => {
        state.isGoodsLoading = true;
      })
      .addCase(goodsTypeUpdate.fulfilled, (state, action) => {
        state.goodsTypeTotal=state.goodsTypeTotal.filter((indGoods)=>indGoods._id!==action.payload.goods._id)
        state.goodsTypeTotal = [
          action.payload.goods,
          ...state.goodsTypeTotal,
        ];
        state.isGoodsLoading = false;
      })
      .addCase(goodsTypeUpdate.rejected, (state, action) => {
        state.isGoodsLoading = true;
      })
      .addCase(goodsTypeList.pending, (state, action) => {
        state.isGoodsLoading = true;
      })
      .addCase(goodsTypeList.fulfilled, (state, action) => {
        state.goodsTypeTotal = action.payload.goodsTypeList;
        state.isGoodsLoading = false;
      })
      .addCase(goodsTypeList.rejected, (state, action) => {
        state.isGoodsLoading = true;
      });
  },
});

export const {} = goodsTypeSlice.actions;
export default goodsTypeSlice.reducer;
