import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button, Modal } from "antd";
import { updatePartner } from "../../redux/Partner/PartnerSlice";
const NotVerifiedPartner = () => {
    const dispatch = useDispatch()
    const [filterData, setFilterData] = useState([]);
    const { notVerifiedPartner } = useSelector((store) => store.partner)
    const { vehicleTotal } = useSelector((store) => store.vehicle)
    const [modal1Open, setModal1Open] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(false);
    console.log(notVerifiedPartner[0], 'notVerifiedPartner');
   
    const handlePartner = (data) => {
        setModal1Open(true);
        setSelectedPartner(data);
    }

    const handleVerify = async () => {
        const formData = {
            _id: selectedPartner._id,
            verified: true
        }
        await dispatch(updatePartner(formData))
        setModal1Open(false);
    }
    
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Dashboard
                        </h4>
                    </div>
                </div>

                <div className="grid grid-cols-1">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50" style={{ marginBottom: '50px' }}>
                        <div className="border-t border-dashed border-default-200">
                            <div className="relative overflow-x-auto">
                                <table className="min-w-full overflow-x-hidden">
                                    <thead className="border-b border-dashed border-default-200">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                                style={{ textAlign: 'center' }}
                                            >
                                                No
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Number
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Address
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Origin City
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Vehicle Number
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-40"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Vehicle Type
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-dashed divide-default-200">
                                        {notVerifiedPartner &&
                                            notVerifiedPartner
                                                .map((data, index) => (
                                                    <tr key={index}>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <b>{index + 1}</b>
                                                        </td>
                                                        <td className="px-6 py-3 whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <h6 className="text-sm font-semibold text-default-700">
                                                                {data.drivername.length > 30 ? (
                                                                    <>{data.drivername.slice(0, 30)}...</>
                                                                ) : (
                                                                    <>{data.drivername.slice(0, 30)}</>
                                                                )}
                                                            </h6>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <b>{data.driverNumber}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <b>{data.address}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <b>{data.originCity}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <b>{data.vehicleNumber}</b>
                                                        </td>
                                                        <td className="px-6 py-3 text-default-600 font-semibold whitespace-nowrap" style={{ textAlign: 'center' }}>
                                                            <b>{
                                                                vehicleTotal.map((indVehicle) =>
                                                                    indVehicle._id === data.vehicleType ? indVehicle.name
                                                                        :
                                                                        ''
                                                                )
                                                            }
                                                            </b>
                                                        </td>
                                                        <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium" style={{ textAlign: 'center' }}>
                                                            <div className="flex items-center justify-center gap-2">
                                                                <button
                                                                    onClick={() => handlePartner(data)}
                                                                    className="inline-flex items-center justify-center h-8 w-24 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white">
                                                                    Proceed
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        <Modal
                                            title={`Do you want to Verify the partner with name ${selectedPartner.drivername}`}
                                            style={{ top: 20 }}
                                            open={modal1Open}
                                            onOk={() => setModal1Open(false)}
                                            onCancel={() => setModal1Open(false)}
                                            footer={[
                                                <Button key="back"
                                                    onClick={()=>setModal1Open(false)}
                                                >
                                                    Cancel
                                                </Button>,
                                                <Button key="submit"
                                                    onClick={()=>handleVerify()}
                                                >
                                                    Verify
                                                </Button>]}
                                        >
                                        </Modal>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200" >
                            <h6 className="text-default-600">Showing {filterData.length} results </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotVerifiedPartner;
