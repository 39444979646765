import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { goodsTypeCreate, verifySlugUrl } from "../../redux/GoodsType/GoodsSlice";

const GoodsType = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [checkslugUrl, setCheckSlugUrl] = useState("");
    const [error, setError] = useState("");
    const [errorColor, setErrorcolor] = useState("");

    const verifyslugurl = async (e) => {
        const value = e.target.value;
        setName(value);
        setCheckSlugUrl("");
        const nameSlug = value
            .trim()
            .toLowerCase()
            .replace(" ", "-")
            .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
            .replace("--", "-")
            .replace("---", "-")
            .replace("----", "-")
            .replace("/", "-")
            .replace("//", "-")
            .replace("///", "-");

        if (nameSlug !== "") {
            let responce = await dispatch(verifySlugUrl(nameSlug));
            if (responce.payload.success) {
                setError("Brand alredy exist");
                setErrorcolor("red");
            } else if (nameSlug.length <= 2) {
                setErrorcolor("red");
                setError("minimum 3 letters");
                setCheckSlugUrl("");
            } else if (nameSlug.length >= 3) {
                setErrorcolor(" ");
                setError("");
                setCheckSlugUrl(nameSlug);
            }
        }
    };

    const handleSubmit = async () => {
        if (name !== '') {
            const formData = {
                name: name,
                slugUrl: checkslugUrl
            }
            const goodType = await dispatch(goodsTypeCreate(formData))
            if (goodType.payload.success) {
                alert('Good Type Created Successfully')
                setName('');
                setCheckSlugUrl('')
            }
        }
    }
    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-6 space-y-6">
                    <div className="flex items-center justify-between w-full print:hidden">
                        <h4 className="text-lg font-semibold text-default-900">
                            Add Goods Type
                        </h4>

                    </div>
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-4 px-5">
                            <h4 className="text-lg font-medium text-default-950 capitalize">
                                Add Goods Type
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-2 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Name *
                                    </label>
                                    <input
                                        type="text"
                                        className={
                                            // nameError !== ""
                                            //     ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                            //     : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fName"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange={(e) => verifyslugurl(e)}
                                    />
                                    <p style={{ color: errorColor }} className="mt-2">
                                        {error}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '60px' }}>
                        <button
                            style={{ background: "#ef4444", color: 'text' }}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                        >
                            <i className="ph-bold ph-x text-lg" /> Reset
                        </button>
                        <button
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                            style={{ background: "#8481ff" }}
                            onClick={() => handleSubmit()}
                        >
                            <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GoodsType;
