import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../redux/Authentication/AuthSlice";
import vehicleReducer from "./Vehicle/VehicleSlice";
import goodsTypeReducer from "./GoodsType/GoodsSlice";
import partnerReducer from "./Partner/PartnerSlice";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    vehicle:vehicleReducer,
    goodsType:goodsTypeReducer,
    partner:partnerReducer,
  },
});
